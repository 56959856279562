@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap");
@import "./myclient.module.scss";
@import "./inbox.module.scss";
@import "./template.module.scss";
@import "./plan.module.scss";
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  padding: 0;
  margin: 0;
  font-family: "Lexend", sans-serif !important;
}

a {
  &:active,
  &:focus {
    color: #c0f121 !important;
  }
  &:focus-visible {
    outline: inherit !important;
  }
}

body,
html {
  font-family: "Lexend", sans-serif !important;
  background: #202020;
}
// general styling

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #e6e6e6;
  -webkit-box-shadow: 0 0 0px 1000px #202020 inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-caret-color: #e6e6e6;
  caret-color: #e6e6e6;
}

.autofill-fix {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #e6e6e6;
    -webkit-box-shadow: 0 0 0px 1000px #030303 inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-caret-color: #e6e6e6;
    caret-color: #e6e6e6;
  }
}

.home-tab {
  .nav-tabs {
    border: none;
    background-color: #343434;
    border-radius: 32px !important;
    padding: 4px 3px 4px 4px !important;
    max-width: 656px;
    margin-bottom: 48px;
    .nav-link {
      width: 162px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 48px !important;
      color: #ffffff !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .nav-link.active {
      background-color: #555 !important;
      border-radius: 48px !important;
      border: transparent;
    }
  }
}

.rs-loader-spin:after {
  border-color: #202020 transparent transparent !important;
}

.main-container {
  max-width: 1440px;
  padding: 0 40px;
  padding-bottom: 40px;
  margin: auto;
}

.role-dropdown {
  .dropdown-menu {
    margin-top: 8px;
    div {
      a.dropdown-item {
        &:hover,
        &.active {
          background-color: transparent;
        }
      }
      &:first-child {
        a.dropdown-item {
          color: #c0f121;
          border-bottom: 1;
          border-bottom: 0.5px solid #71717a;
        }
      }
      &:nth-child(2) {
        a.dropdown-item {
          color: #487afa;
          border-bottom: 0.5px solid #71717a;
        }
      }
      &:last-child {
        a.dropdown-item {
          color: #67e2f4;
        }
      }
    }
  }
}

// new rsuite modal

.foodModal {
  &.rs-modal {
    .rs-modal-dialog {
      .rs-modal-content {
        width: 100%;
        display: inline-flex;
        padding: 24px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 36px;
        border-radius: 12px;
        background: #000;

        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
          0px 3px 4px 0px rgba(0, 0, 0, 0.12),
          0px 1px 5px 0px rgba(0, 0, 0, 0.2);

        .rs-modal-footer {
          &::before,
          &::after {
            content: inherit !important;
          }
        }
      }
    }
    &.avatar-modal {
      .rs-modal-dialog {
        .rs-modal-content {
          padding: 37px 40px 33px;
        }
      }
    }
    &.profile-modal {
      .rs-modal-dialog {
        .rs-modal-content {
          padding: 40px;
          gap: 32px;
        }
      }
    }
  }

  &.permission-modal {
    &.rs-modal {
      .rs-modal-dialog {
        .rs-modal-content {
          padding: 16px 0;
        }
      }
    }
  }
}

// bootstrap modals
.meal-multiselect-modal {
  display: flex;
  max-width: 600px !important;
  padding: 20px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  margin: 0 auto;
  border-radius: 20px !important;
  background: #0e0e0e !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15) !important;

  &.add-user-modal {
    max-width: 430px !important;
    padding: 24px 20px !important;
    gap: 24px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }

  .modal-body,
  .modal-header,
  .modal-footer {
    padding: 0 !important;
    border: none !important;
  }
  .modal-footer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px;
  }
}

//  horizontal  tabs

.get-started-tabs {
  .nav-tabs {
    border: none;
    background-color: #343434;
    border-radius: 8px;
    padding: 4px;
    max-width: 656px;
    margin-bottom: 48px;
    .nav-link {
      width: 162px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      color: #202020;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .nav-link.active {
      background-color: #c0f121;
      border-radius: 8px;
      border: transparent;
    }
  }

  &.with-management-tabs {
    .nav-tabs {
      border: none;
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      border-bottom: 2px solid #343434 !important;
      max-width: 534px;
      margin-bottom: 16px;
      .nav-link {
        width: fit-content;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        color: #e6e6e6 !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding: 0 10px 12px 10px;
        border: transparent;
        border-bottom: 2px solid #343434 !important;
      }
      .nav-link.active {
        background-color: transparent;
        color: #c0f121 !important;
        border-bottom: 2px solid #c0f121 !important;
        border-radius: 0;
        border: transparent;
      }
    }
  }
}

// Vertical tabs

.vertical-tabs {
  display: flex;

  &.w-275 {
    .nav-tabs {
      width: 275px !important;
    }
  }

  .nav-tabs {
    display: flex;
    width: auto;
    height: 674px;
    padding: 32px 20px 20px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #030303;
    border: none !important;

    .nav-item {
      width: 100%;

      .nav-link {
        display: flex;
        width: 100%;
        padding: 12px;
        align-items: center;
        gap: 12px;
        border-radius: 12px;
        border: none !important;
        color: #fff;
        font-size: 16px !important;
        font-weight: 300;
        line-height: 24px !important;
        letter-spacing: 0.5px !important;
        margin-bottom: 20px;
        &.active {
          color: #fff;
          font-weight: 400;
          background: rgba(192, 241, 33, 0.2);
        }
      }
    }
  }
}

// filter-dropdown
.filter-dropdown {
  .dropdown-toggle {
    border-radius: 12px !important;
    border: 1px solid #e6e6e6 !important;
    background-color: transparent !important;
    color: #e6e6e6 !important;
  }

  .dropdown-menu {
    min-width: 110px;
  }
}

// new check

.custom-check {
  &.rs-toggle {
    .rs-toggle-presentation {
      background-color: #202020 !important;
    }
    &.rs-toggle-checked {
      .rs-toggle-presentation {
        background-color: #c0f121 !important;
        color: #202020 !important;
      }
    }
  }

  &.out-line {
    &.rs-toggle {
      .rs-toggle-presentation {
        background-color: transparent !important;
        color: #c0f121 !important;
        border: 1px solid #c8c8c8 !important;
      }
      &.rs-toggle-checked {
        .rs-toggle-presentation {
          background-color: transparent !important;
          color: #c0f121 !important;
          border: 1px solid #c8c8c8 !important;
        }
      }
    }
  }
}

// /* default checkbox input */
.custom-checkbox {
  cursor: pointer;
  input[type="checkbox"] {
    display: none;
    &:checked {
      + {
        .checkmark {
          background-color: #c0f121;
          border: 1px solid #c0f121;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url(../images/Check1.svg);
          }
        }
      }
    }
  }
  .checkmark {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #343434;
    border: 1px solid #343434;
    border-radius: 4px;
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
  }

  &.table-checkBox,
  &.client-card-checkBox {
    .checkmark {
      width: 15px;
      height: 15px;
      background-color: #202020;
      border: 1px solid #e6e6e6;
      border-radius: 2px;
      margin-right: 0;
    }
  }

  &.client-card-checkBox {
    .checkmark {
      overflow: hidden;
      background-color: transparent;
      border: 1px solid #202020;
    }
  }
}

.f4f-mutliselect-droddown {
  .chip {
    background: #c0f121;
    color: #202020;
    img {
      display: none;
    }
  }
  .searchWrapper {
    background: #202020;
    min-height: 44px;
    padding: 11px 12px;
    border-radius: 8px;
    border: none;
  }
  .optionListContainer {
    position: inherit;
    border-radius: 8px;
    background: #202020;
    margin-top: 20px;
    .optionContainer {
      border: 1px solid transparent;
      // display: grid;
      // min-height: 60px;
      // grid: 30px / auto auto auto;
      // grid-gap: 10px;
      .notFound {
        color: #fff;
      }
      li.option {
        display: flex;
        padding: 12px;
        margin-bottom: 12px;
        background: #202020;
        color: #e6e6e6;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
        input[type="checkbox"] {
          width: 20px;
          height: 20px;
          margin-right: 12px;
          accent-color: #c0f121;
        }
      }
    }
  }
}
.f4f-mutliselect-droddown {
  ::-webkit-scrollbar {
    width: 16px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #202020;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #c0f121;
    border: 4px solid transparent;
    border-radius: 6px;
    background-clip: content-box;
  }
}

// tooltip

.tooltip-box {
  &:hover {
    #tooltip-disabled {
      display: block !important;
    }
  }

  #tooltip-disabled {
    display: none !important;
  }

  .tooltip-content-box {
    background: linear-gradient(
        0deg,
        rgba(192, 241, 33, 0.2) 0%,
        rgba(192, 241, 33, 0.2) 100%
      ),
      #030303;
    position: relative;
    &::before {
      content: "";
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      position: absolute;
      top: -18px;
      left: 37px;
      background-image: url(../images/toolTipArrow.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.offcanvas-backdrop {
  background: rgba(125, 125, 125, 0.36) !important;
  backdrop-filter: blur(7.400000095367432px) !important;
}

// .main-container {
/* width */
// .main-template-contet-wrapper {
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #343434;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #c0f121;
  border: 4px solid transparent;
  border-radius: 6px;
  background-clip: content-box;
}
// }
// }

.food-scroll-color {
  ::-webkit-scrollbar-track {
    background: #343434 !important;
  }
}

// email

.email-verify-container {
  .otp-code-wrapper {
    // div {
    margin-right: -16px;
    .otp-input {
      color: #c8c8c8;
      padding: 11px 12px;
      width: 68px !important;
      height: 62px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      border-radius: 8px;
      background: #202020;
    }
    div:nth-child(3)::after {
      content: "-";
      padding-right: 16px;
      color: #c8c8c8;
    }
    // }
  }
}

// tabs

// toastity
.Toastify__toast-container {
  .Toastify__toast {
    .Toastify__toast-icon {
      svg {
        fill: #c0f121;
      }
    }
    .Toastify__close-button {
      svg {
        columns: #c0f121;
      }
    }
  }
}

// table

.rs-table {
  .rs-table-header-row-wrapper {
    .rs-table-row-header {
      background-color: #1a1d24 !important;
    }
  }
  .rs-table-body-row-wrapper {
    .rs-table-cell {
      background-color: #030303;
    }
  }
}

// check box

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
  background-color: #c0f121 !important;
  border-color: #c0f121 !important;
}

.rs-checkbox-wrapper {
  background-color: #343434 !important;
}

// rs-pagination-group

.rs-pagination-group {
  .rs-pagination-group-total {
    color: #7d7d7d !important;
  }

  .rs-pagination-group-limit {
    .rs-picker.rs-picker-select {
      .rs-picker-toggle {
        background-color: transparent !important;
        &:hover,
        &:focus {
          border-color: #c0f121 !important;
        }
      }

      .rs-picker-toggle-value {
        color: #7d7d7d !important;
      }
    }
  }

  .rs-pagination {
    .rs-pagination-btn {
      &.rs-pagination-btn-active {
        border-color: #c0f121 !important;
        color: #c0f121 !important;
      }
    }
  }

  .rs-pagination-group-skip {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #7d7d7d !important;

    .rs-input {
      background-color: transparent !important;
    }
  }
}
.rs-picker-select-menu {
  .rs-picker-select-menu-item {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    &.rs-picker-select-menu-item-active {
      background: rgba(192, 241, 33, 0.2) !important;
      color: #c0f121 !important;
    }

    &:hover {
      background: rgba(192, 241, 33, 0.2) !important;
      color: #c0f121 !important;
    }
  }
}

.add-food-drawer {
  .rs-drawer-content {
    border-radius: 12px 0px 0px 12px !important;
    background-color: #0e0e0e !important;

    .rs-drawer-body-close {
      display: none !important;
    }
  }
}

// p-datatable

.p-datatable {
  border-radius: 8px;
  background: #0e0e0e;
  .p-datatable-wrapper {
    overflow: inherit !important;
    @media (max-width: 992px) {
      overflow-x: scroll !important;
    }

    .p-datatable-thead {
      border-bottom: 1px solid #202020;
      th {
        padding: 16px;
        .p-column-header-content {
          .p-column-title {
            color: #e6e6e6;
            font-family: Lexend;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
    .p-datatable-tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid #202020;
        }
        td {
          padding: 16px;
          color: #e6e6e6;
          font-family: Lexend;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

// new react date picker

.react-date-picker {
  width: 100%;

  &.react-date-black {
    .react-date-picker__wrapper {
      background: #030303 !important;
    }
  }

  .react-date-picker__wrapper {
    padding: 11px 12px 11px 12px;
    gap: 0px;
    border-radius: 8px;
    justify-content: space-between;
    background: #202020;
    border: none;
    width: 100%;

    .react-date-picker__button {
      padding: 0 0 0 8px !important;
    }

    .react-date-picker__inputGroup {
      width: 100%;
      input {
        color: #e6e6e6;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
      }
      input::placeholder {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        letter-spacing: 0.5px !important;
        text-align: left !important;
      }

      .react-date-picker__inputGroup__divider {
        color: #e6e6e6;
      }
    }
  }
  .react-date-picker__calendar {
    .react-calendar {
      width: 307px;
      height: auto;
      flex-shrink: 0;
      border: none;
      border-radius: 8px;
      background: #202020;
      padding: 16px;
      box-shadow: 0px 14px 33.3px 0px rgba(192, 241, 33, 0.04);

      .react-calendar__navigation {
        button {
          &:hover {
            background-color: transparent;
          }
        }
        .react-calendar__navigation__arrow {
          color: #6a6e7a;
        }
        .react-calendar__navigation__label {
          span {
            color: #e6e6e6;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .react-calendar__viewContainer {
        .react-calendar__month-view {
          .react-calendar__month-view__weekdays {
            .react-calendar__month-view__weekdays__weekday {
              color: #e6e6e6;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
          .react-calendar__month-view__days {
            button {
              width: 34px;
              height: 34px;
              color: #e6e6e6;
              flex-shrink: 0;
              border-radius: 4.8px;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              padding: 9px !important;

              &.react-calendar__tile--active {
                color: #c0f121;
                background: rgba(192, 241, 33, 0.4);
              }

              &:hover {
                color: #c0f121;
                background: rgba(192, 241, 33, 0.4);
              }

              &.react-calendar__month-view__days__day--neighboringMonth {
                color: rgba(230, 230, 230, 0.2);
              }
            }
          }
        }
      }
    }
  }
}
