/* tailwind library css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* toast color */
:root {
  --toastify-spinner-color: red;
  --toastify-color-success: #c0f121;
  --toastify-color-progress-dark: #c0f121;
}

.Toastify__toast {
  background: linear-gradient(
      0deg,
      rgba(192, 241, 33, 0.2) 0%,
      rgba(192, 241, 33, 0.2) 100%
    ),
    #030303 !important;
  color: #e6e6e6 !important;
}

.Toastify__progress-bar {
  background: linear-gradient(
      0deg,
      rgba(192, 241, 33, 0.2) 0%,
      rgba(192, 241, 33, 0.2) 100%
    ),
    #030303 !important;
}

/* general tailwind classes */
.secondary-grid {
  @apply bg-secondary1 rounded-[20px] p-[20px];
}
.teritary-grid {
  @apply bg-background rounded-[20px] p-[20px];
}

.stepper-active-class {
  @apply bg-primary1 flex items-center justify-center flex-shrink-0 w-[32px] h-[32px] rounded-full;
}
.stepper-non-active-class {
  @apply bg-textPrimary flex items-center justify-center w-[32px] h-[32px] flex-shrink-0 rounded-full;
}

/* flex  */
.flex-items-center {
  @apply flex items-center;
}

.flex-items-start {
  @apply flex items-start;
}

.flex-items-end {
  @apply flex items-end;
}

.flex-items-baseline {
  @apply flex items-baseline;
}

.flex-between {
  @apply flex justify-between;
}

.flex-justify-end {
  @apply flex justify-end;
}

.flex-center-between {
  @apply flex items-center justify-between;
}

.flex-center-wrap {
  @apply flex items-center flex-wrap;
}

.flex-center-center {
  @apply flex items-center justify-center;
}

.flex-center-start {
  @apply flex items-center justify-start;
}

.flex-center-end {
  @apply flex items-center justify-end;
}

.flex-start-end {
  @apply flex items-start justify-end;
}

.flex-start-between {
  @apply flex items-start justify-between;
}

.flex-start-center {
  @apply flex items-start justify-center;
}

.flex-start-start {
  @apply flex items-start justify-start;
}

.flex-baseline-between {
  @apply flex  items-baseline justify-between;
}
.flex-column {
  @apply flex flex-col;
}

.flex-column-items-end {
  @apply flex flex-col items-end;
}

.flex-center-column {
  @apply flex flex-col items-center justify-center;
}

.flex-end-center-column {
  @apply flex flex-col items-end justify-center;
}

.flex-between-column {
  @apply flex  justify-between flex-col;
}

.flex-items-start-column {
  @apply flex items-start flex-col;
}

.flex-items-center-column {
  @apply flex flex-col items-center;
}

.flex-start-start-column {
  @apply flex flex-col items-start justify-start;
}

.flex-center-start-column {
  @apply flex flex-col items-center justify-start;
}

.flex-row-reverse-start {
  @apply flex flex-row-reverse items-start justify-start;
}

.flex-reverse-items-center {
  @apply flex flex-row-reverse items-center;
}
