.f4f-navbar {
  background-color: #0e0e0e;
  nav {
    max-width: 1440px;
    margin: auto;
    padding: 0 40px;

    height: 100%;
    // padding: 0px 22px;
    .container-fluid {
      padding: 0;
    }
    #navbarScroll {
      flex-grow: 0 !important;
    }
    .collapse {
      visibility: visible;
    }
    @media screen and (min-width: 320px) and (max-width: 992px) {
      .navbar-collapse {
        text-align: left;
        background-color: #0e0e0e;
        padding-left: 20px;
        padding-top: 28px;
        z-index: 99 !important;
        left: 0px;
        position: absolute;
        top: 85px;
        width: 100%;
        .logout-link {
          display: inline-block;
        }
        .profile-dropdown {
          display: none;
        }
        &.show {
          .navbar-nav {
            align-items: flex-start !important;
            gap: 24px !important;
          }
        }
      }
    }
    @media screen and (min-width: 992px) {
      .navbar-collapse {
        justify-content: flex-end;
        display: none;
        .logout-link {
          display: none;
        }
      }
    }

    .nav-link {
      color: #e6e6e6;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
    }

    .nav-link.active {
      color: #c0f121;
    }

    .navbar-dropdown {
      &.profile-dropdown {
        button {
          padding: 0 !important;
        }
      }
      .btn.show {
        color: #c0f121;
        background-color: transparent;
      }
      .nav-dropdown {
        &:hover {
          color: #c0f121;
          background-color: transparent;
        }
        &::after {
          content: "";
          display: none !important;
        }
      }

      &:hover {
        .dropdown-menu {
          display: block;
        }
      }

      .dropdown-menu {
        background: #030303;
        position: absolute;
        left: -134px;
        top: 36px;

        @media (max-width: 992px) {
          left: auto !important;
        }

        .nav-link {
          &:hover {
            color: #c0f121;
          }
        }

        a {
          color: #e6e6e6;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          padding: 12px 16px;
          margin: 0;
          white-space: pre;
          &.active {
            color: #c0f121;
          }
          &:hover {
            color: #c0f121;
          }

          &:hover {
            background: #202020;
          }
        }
      }
    }
  }
}
