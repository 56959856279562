.feed-template-form-dropdwon {
  .feed-custom-form-dropdown {
    width: 100%;
    height: 48px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    border-radius: 8px;
    background: #202020;
    text-align: left;
    border: none;
    padding: 0 16px;
  }

  &.client-select {
    .feed-custom-form-dropdown {
      height: 54px !important;
      border-radius: 12px !important;
      // background: #030303 !important;
    }
  }
  .btn::after {
    content: none !important;
  }
  .btn.show {
    background-color: #202020;
    border-color: #202020;
  }
  .btn:hover {
    background-color: #202020;
  }
  .btn:disabled {
    background-color: #202020;
  }
  .btn:first-child:active {
    background: #202020;
  }
  .dropdown-menu {
    border-radius: 8px;
    background: #202020;

    .dropdown-item {
      padding: 12px;
      color: #e6e6e6;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: 0.5px;
      &:hover {
        background: #c0f121;
        color: #202020;
        svg {
          path {
            fill: #202020;
          }
        }
      }
      &.active {
        background: #c0f121;
        color: #202020;
        svg {
          path {
            fill: #202020;
          }
        }
      }
    }
  }
}

// supplement-name-dropdown
.supplement-name-dropdown {
  .feed-custom-form-dropdown {
    background: #030303;
  }
  .btn {
    &.show {
      background-color: #030303;
      border-color: #030303;
    }
    &:hover {
      background-color: #030303;
    }
  }
  .dropdown-menu {
    background-color: #030303;
  }
}

.main-container {
  &::placeholder {
    color: rgba(230, 230, 230, 0.4);
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(230, 230, 230, 0.4);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(230, 230, 230, 0.4);
  }
}
.sidebar-page-container {
  input[type="radio"] {
    accent-color: #c0f121;
  }
}
