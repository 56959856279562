// meal plan

// progress-line

.progress-line {
  .rs-progress-line-outer {
    border-radius: 0 !important;
  }
  .rs-progress-line-inner {
    border-radius: 0 !important;
    background-color: #ffffff;
  }
  .rs-progress-line-bg {
    border-radius: 0 !important;
    // background-color: #487afa;
  }
}

// meal-chart

.meal-chart {
  .apexcharts-legend {
    // top: 40% !important;
    display: flex;
    // flex-direction: column !important;
    gap: 12px !important;
    justify-content: space-between !important;

    flex-wrap: wrap !important;

    .apexcharts-legend-series {
      margin: 0 !important;
      display: flex;
      align-items: center;
      gap: 10px;

      &:nth-child(2) {
        margin: 20px 0 !important;
      }

      .apexcharts-legend-marker {
        width: 20px !important;
        height: 20px !important;
        flex-shrink: 0;
        border-radius: 4px !important;
      }
      .apexcharts-legend-text {
        color: #fff !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
      }
    }
  }

  .apexcharts-datalabels {
    display: none !important;
  }
  .apexcharts-text.apexcharts-datalabel-value {
    fill: #fff !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
}

// accordion
.pnal-accordion {
  .rs-panel-header {
    .rs-icon {
      display: none !important;
    }
  }
}

.rs-panel-collapse {
  cursor: auto !important;
}

// dropdowns

.food-dropdown-r.rs-dropdown {
  .rs-dropdown-toggle.rs-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 14px !important;
    gap: 5px;
    color: #7d7d7d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px !important;
    border: 1px solid rgba(125, 125, 125, 0.2);

    &:hover {
      background: transparent !important;
    }
  }

  &.active {
    .rs-dropdown-toggle.rs-btn {
      color: #c0f121 !important;
      border: 1px solid rgba(192, 241, 33, 0.2) !important;
      background: rgba(192, 241, 33, 0.2) !important;
    }
  }

  .rs-dropdown-menu {
    right: 0 !important;
    margin-top: 8px !important;
    left: inherit !important;
    max-height: 310px !important;
    width: max-content !important;
    padding: 20px 20px 0px 20px;
    border-radius: 8px;
    background: #343434;
    box-shadow: 0px 14px 33.3px 0px rgba(192, 241, 33, 0.03);
    overflow-y: scroll;

    @media (max-width: 900px) {
      width: auto !important;
    }

    .rs-dropdown-item {
      display: flex;
      padding-bottom: 20px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      background: transparent;
      margin-bottom: 16px;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(230, 230, 230, 0.2);
      }
    }
  }
}

// workout plan

// supplement plan

.input-remove-arrow {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -webkit-appearance: textfield;
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

// food item dropdown
.food-item-dropdown {
  width: 100%;
  button {
    width: 100%;
    background-color: transparent !important;
    height: 29px !important;
    padding: 7px 8px !important;
    border-radius: 5px !important;
    border: 1px solid #202020 !important;
    display: flex !important;
    align-items: center !important;

    .flex {
      font-size: 12px !important;
      font-weight: 400 !important;
      letter-spacing: 0.5px !important;
    }
  }
  .dropdown-menu {
    min-width: auto !important;

    .dropdown-item {
      padding: 4px 6px !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      letter-spacing: 0.5px !important;
    }
  }
}

// tabs desigs

.supplement-tabs {
  border-bottom: none !important;
  .nav-item {
    .nav-link {
      color: #c8c8c8;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin: 0 !important;
      border: 0 !important;
      border-bottom: 1px solid #c8c8c8 !important;
      padding: 0px 12px 12px 12px !important;
      &.active {
        color: #c0f121 !important;
        border: none !important;
        border-bottom: 1px solid #c0f121 !important;
        background-color: transparent !important;
      }
      &:hover {
        border: none !important;
        color: #c0f121 !important;
        border: none !important;
        border-bottom: 1px solid #c0f121 !important;
      }
    }
  }
}

.time-step {
  resize: none !important;
}

.rs-message-info {
  background-color: transparent !important;
  .rs-message-container {
    padding: 0 !important;
  }
}
