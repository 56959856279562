.chat-icon {
  svg {
    width: 24px;
    height: 24px;
  }
}

.user-chats-about {
  ::-webkit-scrollbar-track {
    background: #030303 !important;
  }
}
