.navbar-dropdown {
  .btn.show {
    color: #c0f121;
    background-color: transparent;
  }
  .nav-dropdown {
    &:hover {
      color: #c0f121;
      background-color: transparent;
    }
    &::after {
      content: "";
      display: none !important;
    }
  }

  &:hover {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    background: #030303;

    .nav-link {
      &:hover {
        color: #c0f121;
      }
    }

    a {
      color: #e6e6e6;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      padding: 12px 16px;
      margin: 0;
      white-space: pre;
      &.active {
        color: #c0f121;
      }
      &:hover {
        color: #c0f121;
      }

      &:hover {
        background: #202020;
      }
    }
  }

  &.table-dropdown {
    .dropdown-menu {
      background: #202020;
      padding: 0 16px;
      .nav-link {
        &:hover {
          color: #c0f121;
        }
      }

      a {
        color: #e6e6e6;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        padding: 12px 16px;
        margin: 0;
        white-space: pre;
        &:not(:last-child) {
          padding-bottom: 16px;
          border-bottom: 1px solid rgba(230, 230, 230, 0.2);
        }
        &.active {
          color: #c0f121;
        }
        &:hover {
          color: #c0f121;
        }

        &:hover {
          background: #202020;
        }
      }
    }
  }

  &.meal-dropdown {
    .dropdown-menu {
      border-radius: 8px;
      background: #100f0f;
      // display: flex;
      width: 241px;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;

      a {
        display: flex;
        padding-bottom: 20px;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        white-space: pre;
        &.active {
          color: #c0f121;
        }
        &:hover {
          color: #c0f121;
        }

        &:hover {
          background: #202020;
        }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(230, 230, 230, 0.2);
        }
      }
    }
  }
  &.workout-dropdown {
    button {
      display: flex;
      width: 44px !important;
      height: 44px !important;
      padding: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 8px !important;
      background: #202020 !important;
    }
    .dropdown-menu {
      width: 219px !important;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      background: #202020;
      box-shadow: 0px 4px 30px 0px rgba(192, 241, 33, 0.1) !important;
      transform: translate(-175px, 54px) !important;

      a {
        display: flex;
        padding-bottom: 20px;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        white-space: pre;
        &.active {
          color: #c0f121;
        }
        &:hover {
          color: #c0f121;
        }

        &:hover {
          background: #202020;
        }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(230, 230, 230, 0.2);
        }
      }
    }
  }
}
